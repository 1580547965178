import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

const getConnectivityLogosComponentData = () => {
  const data = useStaticQuery(
    graphql`
      {
        wp {
          mdaThemeOptions {
            themeSettings {
              connectivity {
                logos {
                  image {
                    ...wpImageFluid
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return data;
};

export { getConnectivityLogosComponentData };
