// Default imports
import React from "react";

import GenericWideSection from "./GenericWideSection";
import { getConnectivityLogosComponentData } from "../data/components-ourpartners.data";
import ImagesGallery from "./ImagesGallery";

const ConnectivityLogos = ({
  logos,
  logosAlign,
  galleryClassName,
  numberOfRowsDesktop,
  numberOfColumnsMobile,
  ...props
}) => {
  if (!logos) {
    const compData = getConnectivityLogosComponentData();
    logos = compData.wp.mdaThemeOptions.themeSettings.connectivity.logos;
  }

  return (
    <GenericWideSection vertical {...props}>
      <ImagesGallery
        className={`${galleryClassName || ""}`}
        customClass="connectivity-logos"
        images={logos?.map((logo) => logo.image)}
        style={{
          justifyContent: logosAlign || "center",
          alignItems: "center",
          "--numberOfRowsDesktop": numberOfRowsDesktop,
          "--numberOfColumnsMobile": numberOfColumnsMobile,
        }}
      />
    </GenericWideSection>
  );
};
export default ConnectivityLogos;
