// Default imports
import React from "react";
import WpImageFluid from "./WpImageFluid";

const ImagesGallery = ({ images, className, customClass, ...args }) =>
  images && images.length > 0 ? (
    <div
      className={`images-gallery ${className ? className : ""} ${
        customClass ? customClass : ""
      }`}
      {...args}
    >
      {images.map((image, index) => (
        <div
          className={`images-gallery__item ${
            customClass ? `${customClass}__item` : ""
          }`}
          key={index}
        >
          <WpImageFluid
            image={image}
            className={customClass ? `${customClass}__image` : ""}
          />
        </div>
      ))}
    </div>
  ) : (
    <></>
  );
export default ImagesGallery;
