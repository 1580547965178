import React, { Fragment } from "react";
import ImageSection from "./ImageSection";
import SectionHeader from "./SectionHeader";
import GetInTouchButton from "./GetInTouchButton";
import Section from "./Section";

const ImageSectionsGroup = ({ header, imageSections, displayLastButton }) => (
  <Section>
    {header && (
      <SectionHeader header={header} className="image-section-group__header" />
    )}
    {imageSections?.map((imageSectionWrapper, i) => (
      <Fragment key={i}>
        <ImageSection {...imageSectionWrapper} flipped={i % 2 == 1} />
        {i < imageSections.length - 1 && <GetInTouchButton />}
      </Fragment>
    ))}
    {imageSections && displayLastButton && <GetInTouchButton />}
  </Section>
);

export default ImageSectionsGroup;
