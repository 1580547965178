import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ButtonText from "./ButtonText";
import BackgroundImage from "gatsby-background-image";
import { processCSSVars } from "../helpers/stylesHelpers";
import WpImageFluid from "./WpImageFluid";

const CardContent = ({ item }) => (
  <div className="cards-section__card-content">
    {item.icon && item.options.iconPosition !== "absolute" && (
      <div
        className="cards-section__card-icon"
        style={{
          alignSelf: item.iconPosition,
        }}
      >
        <WpImageFluid
          image={item.icon}
          alt={item.title}
          style={{
            height: "100%",
            width: "100%",
          }}
        />
      </div>
    )}
    {item.title && (
      <h3
        className="cards-section__card-title"
        dangerouslySetInnerHTML={{ __html: item.title }}
      />
    )}

    {item.description && (
      <div
        className="cards-section__card-text"
        dangerouslySetInnerHTML={{ __html: item.description }}
      />
    )}

    {item.button?.link && (
      <ButtonText
        variant={`small,${item.button.variant}`}
        {...item.button.link}
      >
        {item.button.link.title}
      </ButtonText>
    )}
  </div>
);

const CardsSection = ({ category, title, description, items, options }) => (
  <Container className="cards-section">
    {(category || title || description) && (
      <Row>
        <Col style={processCSSVars(options)}>
          {category && (
            <div className="cards-section__category">{category}</div>
          )}
          {title && (
            <h2
              className="cards-section__header"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
          {description && (
            <div
              className="cards-section__text"
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          )}
        </Col>
      </Row>
    )}
    <div className="cards-section__wrapper" style={processCSSVars(options)}>
      {items?.map(({ item }, index) => (
        <div
          key={index}
          className="cards-section__card-container"
          style={processCSSVars(item.options)}
        >
          {item.icon && item.options.iconPosition === "absolute" && (
            <div className="cards-section__card-icon cards-section__card-icon--absolute">
              <WpImageFluid
                image={item.icon}
                alt={item.title}
                style={{
                  height: "100%",
                  width: "100%",
                }}
              />
            </div>
          )}
          <div key={index} className="cards-section__card">
            {item.background?.localFile.childImageSharp?.fluid ? (
              <BackgroundImage
                fluid={item.background?.localFile.childImageSharp?.fluid}
                style={{ height: "100%", width: "100%" }}
              >
                <CardContent item={item} />
              </BackgroundImage>
            ) : (
              <CardContent item={item} />
            )}
          </div>
        </div>
      ))}
    </div>
  </Container>
);

export default CardsSection;
