// Default imports
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import ButtonText from "../components/ButtonText";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

const Hero = ({ header, secondHeader, text, image, category, buttons }) => (
  <BgImage
    image={getImage(image?.localFile.childImageSharp?.gatsbyImageData)}
    className="hero__background"
  >
    <Container className={`hero -blank ${image ? "-white" : ""}`}>
      <Row>
        <Col>
          <Row>
            <Col style={{ display: "flex", flexFlow: "column nowrap" }}>
              {category && <p className="hero__category">{category}</p>}
              {header && (
                <h1
                  className="hero__header"
                  dangerouslySetInnerHTML={{ __html: header }}
                />
              )}
              {secondHeader && (
                <h1
                  className="hero__header hero__header--secondary"
                  dangerouslySetInnerHTML={{ __html: secondHeader }}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={16} style={{ display: "flex", flexFlow: "column nowrap" }}>
              {text && (
                <p
                  className="hero__text"
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                />
              )}

              {buttons && (
                <div className="hero__button-container">
                  {buttons
                    .filter(({ button }) => !!button?.link)
                    .map(({ button }) => (
                      <ButtonText
                        className="hero__button"
                        variant={`small,${button.variant}`}
                        {...button.link}
                      >
                        {button.link.title}
                      </ButtonText>
                    ))}
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </BgImage>
);

export default Hero;
